import {
  type ServerDefinedAction,
  ServerDefinedItem,
  type ServerDefinedItemData,
  serverDefinedItem,
} from './server-defined-item'
import type CommandPalette from '../command-palette-element'
import {verifiedFetch} from '@github-ui/verified-fetch'

interface CommandAction extends ServerDefinedAction {
  id: string
}

interface CommandItemData extends ServerDefinedItemData {
  action: CommandAction
}

@serverDefinedItem
export class CommandItem extends ServerDefinedItem {
  data: CommandItemData
  override _action: CommandAction

  constructor(data: CommandItemData) {
    super(data)

    this.typeahead = data.title
    this.group = 'commands'
  }

  override get action() {
    return this._action
  }

  override async activate(commandPalette: CommandPalette) {
    super.activate(commandPalette)

    const path = commandPalette.getAttribute('commands-path')
    if (!path) return

    const params = commandPalette.query.params()
    params.set('command', this.action.id)

    commandPalette.commandPaletteInput.loading = true

    const response = await verifiedFetch(path, {
      method: 'POST',
      body: params,
    })

    commandPalette.commandPaletteInput.loading = false

    if (response.ok) {
      const result: {action: string; arguments: {[key: string]: string}} = await response.json()
      this.handleResponse(commandPalette, result.action, result.arguments)
    } else {
      // eslint-disable-next-line i18n-text/no-en
      commandPalette.displayFlash('error', 'Failed to run command')
    }
  }

  handleResponse(commandPalette: CommandPalette, action: string, args: {[key: string]: string}) {
    switch (action) {
      case 'displayFlash':
        commandPalette.displayFlash(args.type!, args.message!)
        commandPalette.dismiss()
        break
    }
  }
}
