import type {CommandPalette as CommandPaletteAPI, Item, Page, Provider} from '@github-ui/command-palette'
import {attr, controller, target, targets} from '@github/catalyst'
import {ClientDefinedProviderElement} from './client-defined-provider-element'
import type {CommandPaletteInputElement} from './command-palette-input-element'
import type {CommandPaletteItemElement} from './command-palette-item-element'
import {CommandPaletteItemGroupElement} from './command-palette-item-group-element'
import type {CommandPaletteModeElement} from './command-palette-mode-element'
import type {CommandPalettePageStackElement} from '../../../../components/command_palette/command-palette-page-stack-element'
import type {CommandPaletteTipElement} from './command-palette-tip-element'
import type DetailsDialogElement from '@github/details-dialog-element'
import {GlobalProvidersPage} from './pages/global-providers-page'
import type {ProviderElement} from './provider-element'
import {Query} from './query'
import type {Scope} from './command-palette-scope-element'
import type {ServerDefinedProviderElement} from './server-defined-provider-element'
import {crc32} from '@allex/crc32'
import {debounce} from '@github/mini-throttle/decorators'
import {sendTrackingEvent} from './tracking'

const isMac = () => {
  return navigator.platform.match(/Mac/)
}

const platformMetaKey = isMac() ? 'metaKey' : 'ctrlKey'
const platformModifierKey = isMac() ? 'Meta' : 'Control'

/* eslint-disable-next-line custom-elements/no-exports-with-element */
export const isPlatformMetaKey = (event: Event) => {
  if (!(event instanceof KeyboardEvent)) {
    return false
  }

  return event[platformMetaKey]
}

const DISPLAY_BREAKPOINT_SM = 450
@controller
export default class CommandPalette extends HTMLElement implements CommandPaletteAPI {
  static tagName = 'command-palette'

  commandPaletteInput: CommandPaletteInputElement
  list: HTMLElement
  groups: NodeListOf<CommandPaletteItemGroupElement>
  everActivated = false
  activated = false
  error = false
  modes: CommandPaletteModeElement[]
  defaultMode: CommandPaletteModeElement
  activeMode: CommandPaletteModeElement
  resizeObserver: ResizeObserver

  // default to a blank query
  query: Query = new Query('', '')

  previouslyActiveElement?: HTMLElement
  setupComplete = false
  sessionId = ''

  static attrPrefix = ''
  @attr returnTo = ''
  @attr userId = ''
  @attr defaultOpen = false

  // Hotkeys to open the command palette in various modes are set as data attributes.
  // Users can customize their hotkeys, which are stored in UserSettings.

  // Default hotkeys are assigned here so that the component can function on its own,
  // but GitHub's UserSettings has its own defaults which are passed in through the data attributes.

  // Tip: the logic to handle the keyboard events to activate the command palette
  // currently lives in the `command-palette` bundle file

  // This is the hotkey to open the command palette
  // cmd+k is used in textareas for inserting markdown hyperlinks,
  // so there's an optional secondary activation hotkey that can be used when the user is in a markdown textarea.
  // See the `secondaryActivationHotkey` getter which will return the last segment of this hotkey.
  @attr activationHotkey = 'Mod+k,Mod+Alt+k'

  // This is the hotkey to open the command palette in "command" mode
  @attr commandModeHotkey = 'Mod+Shift+K'

  @target pageStack: CommandPalettePageStackElement

  @targets clientDefinedProviderElements: ClientDefinedProviderElement[]
  @targets serverDefinedProviderElements: ServerDefinedProviderElement[]

  setup() {
    this.modes = Array.from(this.querySelectorAll<CommandPaletteModeElement>('command-palette-mode')!)
    this.defaultMode = this.querySelector<CommandPaletteModeElement>('.js-command-palette-default-mode')!
    this.commandPaletteInput = this.querySelector<CommandPaletteInputElement>('command-palette-input')!
    this.groups = this.querySelectorAll<CommandPaletteItemGroupElement>('command-palette-item-group')!

    if (this.defaultOpen) {
      this.manualToggle(true)
      this.clearReturnToParams()
    }

    window.commandPalette = this

    this.setupComplete = true

    const event = new Event('command-palette-ready', {
      bubbles: true,
      cancelable: true,
    })

    this.dispatchEvent(event)
  }

  connectedCallback() {
    if (!this.setupComplete) {
      this.setup()
    }
  }

  /**
   * This function is responsible for clearing state from the command palette.
   * This is useful for when the command palette moves between PJAX navigation events.
   *
   * By default it resets the input.
   *
   * @param resetInput an optional argument which specifies that the the input should be reset.
   */
  clear(resetInput = true) {
    this.clearProviderCaches()
    this.pageStack.reset()
    if (resetInput) this.resetInput()
  }

  /**
   * This function is responsible for clearing command state from the command palette.
   * This is useful for when the there is a state change on page (eg. Issue close).
   *
   * By default it resets the input.
   *
   * @param resetInput an optional argument which specifies that the the input should be reset.
   */
  @debounce(250)
  clearCommands(resetInput = true): Promise<void> {
    if (!this.everActivated) return Promise.resolve()
    this.clearCommandProviderCaches()
    if (resetInput) this.resetInput()

    return Promise.resolve()
  }

  /**
   * Resets the input to it's default state for the page.
   */
  resetInput() {
    this.commandPaletteInput.inputValue = ''
  }

  // things that should be done every time the command palette is opened
  activate() {
    // Generate a new sessionId every activation for tracking
    this.sessionId = this.generateSessionId()
    this.commandPaletteInput.scopeElement.smallDisplay = this.offsetWidth < DISPLAY_BREAKPOINT_SM
    this.commandPaletteInput.focus()

    this.setActiveModeElement()
    this.setQuery()
    this.toggleTips()
    this.pageStack.commandPaletteActivated()

    this.dispatchEvent(
      new CustomEvent('command-palette-activated', {
        detail: {
          previouslyActivated: this.everActivated,
        },
      }),
    )

    this.activated = true
    this.everActivated = true
    sendTrackingEvent('session_initiated')
  }

  // things that should be done when the command palette is closed
  deactivate() {
    this.activated = false

    this.pageStack.unbindListeners()
    this.clear()

    if (this.previouslyActiveElement) {
      this.previouslyActiveElement.focus()
    }

    sendTrackingEvent('session_terminated')
  }

  generateSessionId() {
    return crc32(`${Date.now()}_${this.userId}_${this.query.path}`).toString()
  }

  manualToggle(open: boolean) {
    const details = this.closest('details')!
    open ? (details.open = true) : details.removeAttribute('open')
  }

  /**
   * Close the command palette.
   */
  dismiss() {
    this.manualToggle(false)
    this.clear()
  }

  // If the activation hotkey has a comma, use the final comma-separated segment
  // as the "secondary" hotkey, which works when a user is focused
  // in a Markdown textarea.
  get secondaryActivationHotkey(): string {
    const hotkeys = this.activationHotkey.split(',')

    if (hotkeys.length > 1) {
      return hotkeys[hotkeys.length - 1]!
    }

    return ''
  }

  get platformActivationHotkey(): string {
    return this.platformHotkey(this.activationHotkey)
  }

  get platformSecondaryActivationHotkey(): string {
    return this.platformHotkey(this.secondaryActivationHotkey)
  }

  get platformCommandModeHotkey(): string {
    return this.platformHotkey(this.commandModeHotkey)
  }

  // Substitutes the `Mod` keyword for the platform's modifier key.
  // Also works around some platform-specific quirks.
  // Returns an empty string if the user has chosen to disable that hotkey.
  platformHotkey(hotkeyString: string): string {
    if (hotkeyString === 'none') return ''

    let hotkey = hotkeyString

    if (isMac()) {
      // the order of mod & alt on Mac platforms needs to be switched around
      // in order to match `eventToHotkeyString` from the hotkey library,
      // but we store them in a standardized format in UserSettings, so it gets swapped around here.
      hotkey = hotkey.replace(/Mod\+Alt/g, 'Alt+Mod')
    }

    // `Mod` is a special keyword that means "Command" or "Control" depending on the platform
    return hotkey.replace(/Mod/g, platformModifierKey)
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onInput() {
    if (!this.everActivated) return

    this.commandPaletteInput.typeahead = ''
    this.setActiveModeElement()
    this.setQuery()
    this.toggleTips()
    this.updateOverlay()
  }

  updateOverlay() {
    const mode = this.getMode()
    this.commandPaletteInput.overlay = mode

    for (const group of this.groups) {
      group.renderElement(mode)
    }

    if (mode && this.getTextWithoutMode() === '') {
      const placeholder = this.getModeElement().placeholder || ''
      this.commandPaletteInput.showModePlaceholder(placeholder)
    } else {
      this.commandPaletteInput.showModePlaceholder('')
    }
  }

  itemsUpdated(event: Event) {
    if (!(event instanceof CustomEvent)) return

    const currentItems = event.detail.items as Item[]
    const currentItemsExcludingFooter = currentItems.filter(
      item => item.group !== CommandPaletteItemGroupElement.footerGroupId,
    )
    const currentItemsExcludingFooterAndHelp = currentItemsExcludingFooter.filter(
      item => !item.group || !CommandPaletteItemGroupElement.helpGroupIds.includes(item.group),
    )

    const hasHelpItems = currentItemsExcludingFooter.length > currentItemsExcludingFooterAndHelp.length
    const isEmpty = currentItemsExcludingFooterAndHelp.length === 0 && this.activated

    if (currentItemsExcludingFooterAndHelp.length > 0) {
      // if we have any actual items, we can remove the empty state immediately
      // instead of waiting for results to finish to make the UI a bit more responsive
      this.toggleEmptyState(false, hasHelpItems)
    } else if (isEmpty) {
      // if providers have finished and it's still empty,
      // we know that we should show the empty state and toggle the tips.
      this.toggleEmptyState(true, hasHelpItems)
      this.toggleTips()
    }

    this.toggleErrorTips()
  }

  loadingStateChanged(event: Event) {
    if (!(event instanceof CustomEvent)) return
    this.commandPaletteInput.loading = event.detail.loading
  }

  pageFetchError(event: Event) {
    if (!(event instanceof CustomEvent)) return

    this.error = true
    this.toggleErrorTips()
  }

  selectedItemChanged(event: Event) {
    if (!(event instanceof CustomEvent)) return
    const item = event.detail.item as Item
    const isDefaultSelection = event.detail.isDefaultSelection
    this.updateTypeahead(item, isDefaultSelection)
  }

  /**
   * Grabs first character from the input and checks if it matches against a mode. Once the mode is
   * found, it memoizes it for fast retrieval.
   * A mode is considered active if the first character of the input matches the mode character,
   * and if mode is valid for the current scope.
   *
   */
  setActiveModeElement() {
    const firstChar = this.commandPaletteInput.inputValue.substring(0, 1)

    const currentMode = this.modes
      .filter(mode => mode.active(this.query.scope, firstChar))
      .find(mode => mode.character() === firstChar)

    this.activeMode = currentMode || this.defaultMode
    this.pageStack.currentMode = this.activeMode.character()
  }

  /**
   * Grabs the current mode, query (without the mode), scope, subject, and return_to params and
   * memoizes them in the command-palette-element. Calling this function ensures that `this.query`
   * is up to date.
   *
   */
  setQuery() {
    this.query = new Query(this.getTextWithoutMode().trimStart(), this.getMode(), {
      scope: this.commandPaletteInput.scope,
      subjectId: this.pageStack.defaultScopeId,
      subjectType: this.pageStack.defaultScopeType,
      returnTo: this.returnTo,
    })

    this.pageStack.currentQueryText = this.getTextWithoutMode().trimStart()
  }

  /**
   * Returns the memoized mode set by `setActiveModeElement()`.
   *
   * @returns the active mode element
   */
  getModeElement(): CommandPaletteModeElement {
    return this.activeMode
  }

  /**
   * Grabs first character from input and checks if it is a mode character that is enabled for the current scope
   * If so, it returns that character.
   *
   * @returns mode character
   */
  getMode(): string {
    return this.getModeElement()?.character()
  }

  /**
   * Returns user input with mode character removed, if present. For example, if
   * the user types `>switch`, this will return `switch`.
   *
   * @returns user input without mode prefix
   */
  getTextWithoutMode() {
    if (!this.commandPaletteInput) return ''

    const text = this.commandPaletteInput.inputValue
    const modeChar = this.getMode()

    if (modeChar && text.startsWith(modeChar)) {
      return text.substring(1)
    }

    return text
  }

  get selectedItem(): CommandPaletteItemElement | undefined {
    return this.pageStack.currentPage.selectedItem
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onSelect(event: Event) {
    if (this.selectedItem) {
      this.selectedItem.item.select(this)
    } else {
      // Tell the input that there was nothing to scope into, or autocomplete
      event.preventDefault()
    }
  }

  autocomplete(item: Item) {
    sendTrackingEvent('autocompleted', item)

    const input = this.commandPaletteInput
    if (item.typeahead !== undefined) {
      input.inputValue = input.overlay + item.typeahead
    } else {
      input.inputValue = input.overlay + item.title
    }
  }

  setScope(newScope?: Scope) {
    sendTrackingEvent('scoped')

    const scope = newScope ? newScope : this.commandPaletteInput.scope

    for (const token of scope.tokens) {
      const isLastToken = token === scope.tokens[scope.tokens.length - 1]

      const page = new GlobalProvidersPage({
        title: token.value,
        scopeId: token.id,
        scopeType: token.type,
      })

      this.pageStack.push(page, !isLastToken)
    }

    this.commandPaletteInput.inputValue = ''
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onDescope() {
    this.toggleEmptyState(false, false)
    this.pageStack.pop()
    this.toggleTips()
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onInputClear() {
    this.pageStack.clear()
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onKeydown(event: KeyboardEvent) {
    /* eslint eslint-comments/no-use: off */
    /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
    if (event.key === 'Enter' && this.selectedItem) {
      this.selectedItem?.activate(this, event)

      event.preventDefault()
      event.stopPropagation()
    } else if (event.key === 'ArrowDown') {
      this.navigateToItem(1)
      event.preventDefault()
      event.stopPropagation()
    } else if (event.key === 'ArrowUp') {
      this.navigateToItem(-1)
      event.preventDefault()
      event.stopPropagation()
    } else if (this.isCopyEvent(event) && this.selectedItem) {
      this.selectedItem.copy(this)

      event.preventDefault()
      event.stopPropagation()
    }
    /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
  }

  close(event: Event) {
    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
    if (event instanceof KeyboardEvent && event.key !== 'Enter') return

    const dialog = document.querySelector<DetailsDialogElement>('.command-palette-details-dialog')!
    dialog.toggle(false)
    event.stopImmediatePropagation()
    event.preventDefault()
  }

  navigateToItem(diff: number) {
    this.pageStack.navigate(diff)
  }

  toggleTips() {
    const availableTips = this.modeTips.filter(tipElement => tipElement.available(this.query))
    const tipToShow = availableTips[Math.floor(Math.random() * availableTips.length)]

    for (const tip of this.modeTips) {
      tip.hidden = !(tipToShow === tip)
    }

    this.pageStack.hasVisibleTip = !!tipToShow
    this.pageStack.currentPage.recomputeStyles()
  }

  toggleEmptyState(isEmpty: boolean, hasHelpItems: boolean) {
    for (const emptyState of this.emptyStateElements) {
      emptyState.toggle(this.query, isEmpty)
    }

    if (!hasHelpItems && isEmpty) {
      const helpProvider = this.serverDefinedProviderElements.find(element => element.type === 'help')

      if (helpProvider) {
        this.pageStack.currentPage.fetch([helpProvider.provider], {isEmpty: true})
      }
    }
  }

  toggleErrorTips() {
    for (const tip of this.errorStateTips) {
      tip.toggle(this.query, false, this.error)
    }
  }

  inputReady(event: Event) {
    if (!(event instanceof CustomEvent)) return
    if (this.resizeObserver) return

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        this.commandPaletteInput.scopeElement.smallDisplay = entry.contentRect.width < DISPLAY_BREAKPOINT_SM
      }
    })

    this.resizeObserver.observe(this)
  }

  updateInputScope(event: Event) {
    if (!(event instanceof CustomEvent)) return
    this.commandPaletteInput.scope = this.pageStack.scope
    this.setQuery()
  }

  updateTypeahead(selectedItem: Item, isDefaultSelection = false) {
    if (this.getTextWithoutMode() === '' && (!selectedItem || isDefaultSelection)) {
      this.commandPaletteInput.typeahead = ''
    } else if (selectedItem) {
      this.commandPaletteInput.typeahead = selectedItem.typeahead ?? selectedItem.title ?? ''
    }
  }

  /**
   * See if this keyboard event should be treated like a copy command.
   *
   * This relies on two checks:
   * 1. See if the keypress maps to the common copy keyboard shortcut for the operating system.
   * 2. Ensure the user hasn't selected any text (if they have, we don't want to intercept).
   *
   * @param event
   * @returns true when command palette should respond to event with copy
   */
  isCopyEvent(event: KeyboardEvent) {
    /* eslint eslint-comments/no-use: off */
    /* eslint-disable @github-ui/ui-commands/no-manual-shortcut-logic */
    if (this.commandPaletteInput.textSelected()) return false

    if (isMac()) {
      return event.metaKey && event.key === 'c'
    } else {
      return event.ctrlKey && event.key === 'c'
    }
    /* eslint-enable @github-ui/ui-commands/no-manual-shortcut-logic */
  }

  setQueryScope() {
    this.query.scope = this.commandPaletteInput.scope
  }

  get providerElements(): ProviderElement[] {
    return [...this.serverDefinedProviderElements, ...this.clientDefinedProviderElements]
  }

  get commandsProviderElements() {
    return this.providerElements.filter(providerElement => providerElement.provider?.hasCommands)
  }

  clearProviderCaches() {
    for (const providerElement of this.providerElements) {
      providerElement.provider?.clearCache()
    }
  }

  clearCommandProviderCaches() {
    for (const commandProviderElement of this.commandsProviderElements) {
      commandProviderElement.provider?.clearCache()
    }
  }

  registerProvider(providerId: string, provider: Provider) {
    const existingProviderElement = this.querySelector(`client-defined-provider[data-provider-id="${providerId}"]`)
    if (existingProviderElement) {
      existingProviderElement.remove()
    }

    const providerElement = ClientDefinedProviderElement.build(providerId, provider)
    this.appendChild(providerElement)
  }

  pushPage(page: Page, clearExistingPagesBeforePush = false) {
    if (clearExistingPagesBeforePush) this.pageStack.clear(false)

    this.pageStack.push(page)
    this.resetInput()
  }

  get tipElements() {
    const tips = this.querySelectorAll<CommandPaletteTipElement>('command-palette-tip')
    return Array.from(tips)
  }

  get modeTips() {
    return this.tipElements.filter(tipElement => !tipElement.onEmpty && !tipElement.onError)
  }

  get emptyStateElements() {
    return this.tipElements.filter(tipElement => tipElement.onEmpty)
  }

  get errorStateTips() {
    return this.tipElements.filter(tipElement => tipElement.onError)
  }

  get placeholder() {
    return this.getAttribute('placeholder') || ''
  }

  clearReturnToParams() {
    const params = new URLSearchParams(location.search)
    params.delete('command_palette_open')
    params.delete('command_query')
    params.delete('command_mode')
    params.delete('clear_command_scope')
    history.replaceState(null, '', `?${params}${location.hash}`)
  }

  displayFlash(type: string, message: string, durationMs = 5000) {
    const toastContainer = document.querySelector<HTMLDivElement>('.js-command-palette-toasts')
    if (!toastContainer) return

    const everyToast = toastContainer.querySelectorAll<HTMLDivElement>('.Toast')
    for (const toast of everyToast) {
      toast.hidden = true
    }

    const toast = toastContainer.querySelector<HTMLDivElement>(`.Toast.Toast--${type}`)
    if (!toast) return

    const toastContent = toast.querySelector('.Toast-content')!
    toastContent.textContent = message

    toast.hidden = false
    setTimeout(() => {
      toast.hidden = true
    }, durationMs)
  }
}
